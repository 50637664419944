/* eslint-disable jsx-a11y/heading-has-content */
import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import "./Hero.css"; // If you're using external styling
import { TextPlugin } from "gsap/TextPlugin";
import { Box, Image } from "@chakra-ui/react";
import { getTranslation } from "../../utility/Translation";
import { useLayoutDirection } from "../../layout/LayoutDireactionContext";
import { Link } from "react-router-dom";

gsap.registerPlugin(TextPlugin);
const Hero: React.FC = () => {
  const { isRTL } = useLayoutDirection();
  const headingRef = useRef<HTMLHeadingElement | null>(null);
  const imageRef = useRef<HTMLDivElement | null>(null);
  const descriptionRef = useRef<HTMLParagraphElement | null>(null);
  const buttonRef = useRef<HTMLParagraphElement | null>(null);
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

  useEffect(() => {
    // GSAP animation for the heading text scramble reveal

    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    gsap.to(headingRef.current, {
      duration: 2,
      ease: "power4.out",
      text: getTranslation("welcome_txt", isRTL),
      scrambleText: {
        text: "Welcome to Our Website!",
        chars: "0123456789!@#$%^&*",
        speed: 0.3,
      }, // Scramble effect
      delay: 0.5, // Delay for a dramatic effect
    });

    // GSAP animation for the description text reveal
    gsap.fromTo(
      descriptionRef.current,
      { opacity: 0, y: 20 }, // Start with opacity 0 and below its final position
      {
        opacity: 1,
        y: 0, // Move to its final position
        duration: 1,
        ease: "power4.out",
        delay: 2.5, // After the heading reveal
      }
    );
    gsap.fromTo(
      buttonRef.current,
      { opacity: 0, y: 20 }, // Start with opacity 0 and below its final position
      {
        opacity: 1,
        y: 0, // Move to its final position
        duration: 1,
        ease: "power4.out",
        delay: 2.5, // After the heading reveal
      }
    );

    // GSAP animation for the image (right section)
    gsap.fromTo(
      imageRef.current,
      { opacity: 0, x: 100 }, // Start with opacity 0 and from the right
      {
        opacity: 1,
        x: 0, // Move to its final position
        duration: 1.2,
        ease: "power4.out",
        delay: 1, // Slight delay to stagger the animations
      }
    );
  }, [isRTL]);

  return (
    <div
      className="hero-section"
      style={{
        overflow: "hidden",
        height: screenWidth > 400 ? "100vh" : "155vh",
        position: "relative",
        backgroundColor: "white", // Fallback background color
        marginBottom: "20px",
      }}
    >
      {/* Background Image with Glass Effect */}
      {/* <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: screenWidth > 400 ? "100vh" : "150vh",
        }}
      > */}
      {/* Actual background image */}
      {/* <img
          src="https://static.vecteezy.com/system/resources/previews/036/114/812/large_2x/ai-generated-futuristic-city-at-night-with-illuminated-skyscrapers-and-high-rise-buildings-ai-generated-free-photo.jpg"
          alt="Background"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            filter: "blur(2px)", // Apply blur to the background image
          }}
        /> */}
      {/* </div> */}

      {/* Front Image with Shadow and Increased Size */}
      {/* <div
        style={{
          position: "absolute",
          top: "53%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "70%", // Increased width
          height: "100%", // Maintain aspect ratio
          maxHeight: "80%", // Ensure it doesn't exceed the viewport height
          boxShadow: "16px 16px 16px rgba(0, 0, 0, 0.7)", // More pronounced shadow effect
          zIndex: 2, // Above the background
        }}
      >
        <img
          src="https://static.vecteezy.com/system/resources/previews/036/114/812/large_2x/ai-generated-futuristic-city-at-night-with-illuminated-skyscrapers-and-high-rise-buildings-ai-generated-free-photo.jpg" // Same image for consistency
          alt="Hero"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </div> */}

      {/* Left Section (Text Content) */}
      <div
        className="hero-left"
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start", // Align text to the left
          padding: "0 50px", // Add some padding for spacing
          position: "relative",

          zIndex: 3,
        }}
      >
        <h1
          ref={headingRef}
          style={{
            color: "black",
            fontSize: "3rem", // Reduced font size
            fontWeight: "bold",
            // textShadow: "3px 3px 12px rgba(0, 0, 0, 0.8)",
            marginTop: "40px",
            marginBottom: "20px", // Space between heading and description
          }}
        ></h1>
        <p
          ref={descriptionRef}
          style={{
            color: "black",
            fontSize: "1.2rem",
            lineHeight: "1.6",
          }}
        >
          {getTranslation("features_a", isRTL)}
          <br />
          {getTranslation("features_b", isRTL)}
          <br />

          {getTranslation("features_c", isRTL)}
          <br />
          <br />

          {getTranslation("features_list", isRTL)}
          {/* Discover the future of digital experiences with our cutting-edge
          technology and innovation. Let's build something amazing together. */}
        </p>
        <Box py={5} ref={buttonRef}>
          <Box>
            <Link to="https://apps.apple.com/sa/app/tkrm-%D8%AA%D9%83%D8%B1%D9%85/id6468667276">
              <img
                src="https://1000logos.net/wp-content/uploads/2020/08/apple-app-store-logo.jpg"
                alt="appstore"
                width={"300px"}
              />
            </Link>
          </Box>
        </Box>
      </div>

      {/* Text Overlay */}
      {/* <div
        style={{
          position: "relative",
          zIndex: 3,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <h1
          ref={headingRef}
          style={{
            color: "#fff",
            fontSize: "4rem",
            fontWeight: "bold",
            textAlign: "center",
            textShadow: "3px 3px 12px rgba(0, 0, 0, 0.8)", // Slightly more pronounced shadow
          }}
        >
          Welcome to Our Website!
        </h1>
      </div> */}

      {/* <Box
        flex={1}
        position="relative"
        display={{ base: "none", md: "block" }} // Hide on small screens, show on medium and larger screens
      >
        <Image
          src="https://static.vecteezy.com/system/resources/previews/036/114/812/large_2x/ai-generated-futuristic-city-at-night-with-illuminated-skyscrapers-and-high-rise-buildings-ai-generated-free-photo.jpg"
          alt="Hero"
          objectFit="cover"
          width="100%"
          height="100%"
        />
      </Box> */}
    </div>
  );
};

export default Hero;
