const data = {
  resources: {
    user_name: ["User Name", "اسم المستخدم"],
    please_enter_the_name: ["Please enter the name", "الرجاء إدخال الاسم"],
    login: ["Login", "دخول"],
    select_country_code: ["Select country code", "اختار مفتاح الدولة"],
    enter_your_company: [
      "Enter your company name",
      "الرجاء ادخال اسم الشركة او المؤسسة",
    ],
    select_your_role: ["Select your role", "الرجاء اختيار منصبك"],
    about: ["About", "نبذة عن النشاط"],
    terms: ["Terms", "الشروط والاحكام"],
    name_is_required: ["Name is Required", "الاسم مطلوب"],
    submit: ["Submit", "إرسال"],
    welcome_txt: ["QUICKLY Find Nearest Restroom", "محتاج دورة مياه"],
    go_txt: [
      "Gotta go? No worries! Looking for a restroom nearby, Discover the ultimate convenience with our app designed to help you find nearby restrooms. Our app provides you with the nearest restroom and its exact location.",
      "تبحث عن حمام قريب، تطبيق تكرم بسرعة راح يبحث لك عن أقرب دورة مياه حولك مع تطبيقنا المصمم ببساطة لمساعدتك في العثور على الحمامات القريبة منك وقت حاجتك. تطبيقنا يستعرض لك أقرب حمام مع موقعه و مميزاته.",
    ],
    features_a: [
      "Accurate Locations: Get precise directions to the nearest restroom.",
      "مواقع قريبة منك: راح نستعرض لك مجموعة من أقرب الحمامات اللي حولك و تختار الأنسب لك",
    ],
    features_b: [
      "User Ratings: Rate and review restrooms to help others find clean and well-maintained facilities.",
      "نوضح لك الفئات: ابحث بسهولة عن الحمامات الخاصة والعامة في الحدائق، المساجد، محطات الوقود، المولات، وغيرها",
    ],
    features_c: [
      "Categories: Find both public and private restrooms easily in parks, mosques, gas stations, malls, and more",
      " تقييمات المستخدمين: تقدر تطلع على تقييمات المستخدمين لكل حمام و أيضا تقدر تقيمها.",
    ],
    features_list: [
      "Make restroom searches effortless and reliable with our app, ensuring comfort and convenience wherever you are in Saudi Arabia.",
      "حريصين نطور تجربة توفير الحمامات بطريقة سهلة ومبسطة وين ما كنت بالسعودية.",
    ],
    company_name_required: ["Company Name is Required", "اسم الشركة مطلوب"],
    role_is_required: ["Role is required", "المنصب مطلوب"],
    complete_recaptcha: [
      "Please complete the reCAPTCHA",
      "الرجاء إكمال عملية التحقق",
    ],
    others: ["Others", "أخرى"],
    purchasing: ["Purchasing", "المشتريات"],
    owner: ["owner", "المالك"],
    management: ["Management", "الإدارة"],
    your_role_company: ["Your Role in the company", "صفتك في الشركة "],
    company: ["Company ", "الشركة"],
    mobile_number: ["Mobile number", "رقم التواصل"],
    lang_rtl: ["EN", "عربي"],
    access_store: [
      "To access the restroom , you need to use the QR under your invoice from the store",
      "لدخول دورة المياه تحتاج استخدام رمز الاستجابة السريع  ال QR   الموجود اسقل فاتورة المشتريات للمتجر",
    ],
    outside_home: [
      "You need a restroom outside home and the public restrooms are closed, or you are looking for a more appropriate alternative, we got you.",
      "تحتاج دورة مياه خارج البيت والحمامات العامة مقفله او تبحث عن بديل أنسب عندنا الحل",
    ],
    suffering_is_over: ["The suffering is over!", "المعاناة انتهت !"],
    saudi_arabia_countries: [
      "Saudi Arabia and many countries suffer from the availability of public restroom, and offering your store for reservation by the public may constitute an important additional source of income for your facility, especially when your site is located in an important place where there are fewer toilets",
      "تعاني السعودية و كثير من الدول من توفر الحمامات العامة و بعرض متجرك للحجز من قبل العامة قد يشكل مصدر دخل إضافي هام لمنشأتك خصوصا عند وجود موقعك بمكان هام  تقل فيه دورات المياه",
    ],
    additional_income: [
      "Additional income may surprise you",
      "دخل إضافي قد يبهرك ",
    ],
    based_on_desires: [
      "Based on your desires, you can pre-display or hide your store’s restroom in the app. It can be hidden during peak times or at the end of the week, or displayed during the day and hidden in the evening, or hidden during seasons and other periods, with complete control by you",
      "بناء على رغباتك  تستطيع بشكل مسبق عرض أو إخفاء دورة مياه متجرك بالتطبيق، حيث يمكن إخفاءها بأوقات الذروة أو بنهاية الأسبوع أو عرضها نهارا و إخفاءها مساءا او إخفاءها فترة المواسم وغيرها بتحكم كامل بواسطتك",
    ],
    complete_control: ["Complete control by you", "تحكم كامل من قبلك "],
    tkrm_control_panel: [
      "The TKRM system helps you make the most of your store’s restroom by providing a free control panel that helps you operate efficiently, maintain a reputation that is in line with your expectation , and address any challenges in an automated manner.",
      " يساعدك نظام تكرم للإستفادة القصوى من دورة مياه متجرك بتقديم لوحة تحكم مجانية يساعدك بكفاءة التشغيل والحفاظ على سمعة تتماشى مع تطلعاتك و معالجة أي تحديات بطريقة آلية  ",
    ],
    system_with_detail: [
      "System with detailed helpful information",
      "نظام مع معلومات تفصيلية مساعده",
    ],
    when_displaying: [
      "When displaying the restroom of your store through the app, the name of your store and its location are hidden until the payment is completed, and it appears only to the customer upon completion of the purchase",
      "عند عرض دورة مياه متجرك عبر التطبيق اسم متجرك و  موقعه مخفي حتى يتم إكتمال الدفع ويظهر فقط للعميل عند اتمام الشراء",
    ],
    your_privacy_store: ["Your privacy is preserved", "خصوصيتك محفوظة "],
    most_users_of_app: [
      "Most of the users of our app are tourists or people traveling in their cars, by using the restroom of your store, you will make them know it and increase the chances of buying, and later there will be additional ways to provide them with offers through the app",
      "معظم مستخدمي تطبيقنا من السياح أو الأشخاص المتنقلين بسيارتهم  باستخدامهم لدورة مياه متجرك ستجعلهم يتعرفون عليه وتزيد من فرص الشراء وسيكون هنالك لاحقا طرق إضافية لتزويدهم بعروض عبر التطبيق",
    ],
    we_help_get: ["We help you get new clients", "نساعدك للحصول على عملاء جدد"],
    we_have_system: [
      "We have a system integrated to all point of sale systems that recognizes only the invoice for your store and enables the customer to simply enter the restroom",
      "لدينا نظام مرتبط بجميع أنظمة نقاط البيع يمكنك من الربط المباشر بحيث يتعرف على الفاتورة الخاصة بمتجرك و تمكن العميل من الدخول ببساطة لدورة الميا",
    ],
    full_pos: ["Full Integration with POS", "ربط كامل مع نقاط البيع "],
    using_tkrm_system: [
      "Using the TKRM system makes it easier for your work team to operate an important facility that needs constant attention, starting with restricting access to your customers only and avoiding embarrassment from external customers, as entry is not allowed without an invoice.",
      "استخدامك لنظام تكرم يسهل لفريق عملك عملية تشغيل مرفق هام يحتاج عناية دائمة بداية بقصر الدخول على عملائك فقط و عدم الوقوع بالإحراج من العملاء الخارجيين حيث لا يسمح الدخول دون وجود فاتورة",
    ],
    easier_operation: ["Easier operation", "تشغيل أسهل "],
    tkrm_lock_device: [
      "A TKRM locking device helps manage the restroom so that only your customers enter it through the purchase invoice and helps you receive important data such as cleaning alerts, peak hours, etc. in a fully automated manner.",
      "جهاز قفل تكرم يساعد بإدارة دورة المياه بحيث يدخل لها فقط عملائك عن طريق فاتورة الشراء ويساعدك بتلقي بيانات هامة مثل تنبيه للتنظيف و ساعات الذروة وغيرها بطريقة مؤتمتة بالكامل ",
    ],
    integrated_system: [
      "An integrated system for managing your store's restroom",
      "نظام متكامل لإدارة دورة مياه متجرك ",
    ],
    utilize_business: [
      "Utilize it to promote your business to tourists and locals, creating an additional income stream during less busy times.",
      "اضفها بتطبيقنا لتمكين السياح والمتجولين من استخدامها لإضافة مصدر دخل اضافي خلال الأوقات الأقل ازدحامًا.",
    ],
    feature_owner: ["Features for Stores Owner", "مميزات للمتاجر "],
    we_have_solution: [
      "We have a solution that helps you manage access to the restroom and allow entry to only those who hold your store’s invoice, and another option for you to get additional income by displaying your store’s restroom on the TKRM app for a fee.",
      "عندنا لك حل يساعدك بإدارة الدخول لدورة المياه والسماح لدخول دورة المياه فقط لمن يحمل فاتورة متجرك وخيار آخر لك للحصول على دخل إضافي بعرض دورة مياه متجرك بتطبيق تكرم مقابل رسوم.  ",
    ],
    manage_store: [
      "Managing your store's restrooms is a challenge for you!",
      "إدارة دورات مياه متجرك تشكل لك تحدي !",
    ],
    you_need_restroom: [
      "You need a restroom outside home and the public restrooms are closed, or you are looking for a more appropriate alternative, we got you.",
      "تحتاج دورة مياه خارج البيت والحمامات العامة مقفله او تبحث عن بديل أنسب عندنا الحل",
    ],
    feature_user: ["Features  for Users", "مميزات للمستخدمين "],
    tkrm_application: [
      "TKRM application with public Restroom!",
      "تطبيق تكرم مع الحمامات العامة",
    ],
    tkrm_complementary: [
      "TKRM is another complementary option for public restrooms that seeks to provide an additional option that provides a new network of restrooms that can be used with an agreed mechanism between the application and the stores, in which there is privacy for the person and many other features.",
      "تكرم خيار آخر مكمل للحمامات العامة يسعى لتوفر خيار إضافي يوفر شبكة جديدة من دورات المياه يمكن استخدامها بآلية متفق عليها بين التطبيق والمتاجر فيها خصوصية للشخص وعدة مميزات أخرى ",
    ],
    find_nearest: [
      "Find the nearest restroom in the TKRM app. After you pay, we will inform you of the location of the store you are going to and the way to it inside the store in detail. You pass the QR on your mobile phone and enter without asking anything from anyone and in complete privacy",
      "ابحث عن أقرب دورة مياه بتطبيق تكرم .. بعد ما تدفع بنعلمك مكان المتجر اللي بتروح له و أيضا الطريق له داخل المتجر بالتفصيل وتمرر الكود بجوالك وتدخل بدون ما تطلب أي شيء من أحد وبخصوصية تامة",
    ],
    dignity_preserved: ["Your dignity is preserved", "كرامتك محفوظة "],
    midst_crowd: [
      "In the midst of crowds and you need to answer the call of nature, NO worries, will take you to the nearest restroom around, provide you its amenities and you choose the one you like",
      "وسط الزحمة و احتجت تقضي حاجتك، ماعليك بندلك لأقرب حمام حولك ونعرض لك مواصفاته وتختار اللي يعجبك",
    ],
    cut_knot: ["We cut the knot!", "نفك الزنقه !"],

    no_long_need: [
      "no longer need to go to a hotel and act like you have reservations to use the restroom, or have to go to a restaurant with your kid, and come up with creative acts  every time! especially when the situation is tense and your nerves with some guilt to it alter ",
      "ماعاد يحتاج تدخل فندق وتسوي بتحجز علشان تروح الحمام أو تضطر تدخل مطعم مع ولدك وهات إبداعات كل مره تصريفه .. خاصة لما يكون الوضع متأزم وعلى أعصابك .. وزد عليها شوية تأنيب ضمير",
    ],
    acting_awkward: [
      "Acting and Awkward movements are no longer needed!",
      "التمثيل وحركات النص كم ماعاد تحتاجها! ",
    ],
    add_restroom_store: ["Add your Restrooms ", " أضف دورة مياه متجرك "],
    book_restroom: ["Book Restroom", " استخدم دورة مياه "],
    download_app: [
      "Download TKRM app and use any listed restroom from stores partnered with us",
      "حمل تطبيق تكرم واستخدم أي من دورات المياه المعروضة من قبل المتاجر المشتركة معنا",
    ],
    look_near_restroom: [
      "Looking for a nearby restroom ? ",
      "محتاج دورة مياه قريبة؟ ",
    ],
    delete: ["Delete", "حذف "],
    pay_by_apple: ["Pay by Apple", "الدفع بواسطة ابل باي"],
    session_timedOut: ["Session has timed out", "انتهت فترة القيام بالطلب"],
    payment_or_order_not_done: [
      "Payment / order are not done yet",
      "الدفع/الطلب لم يتم",
    ],
    please_try_order: ["Please try placing Order", "الرجاء القيام بالطلب"],
    place_order_first_then_pay: [
      "You have to place order first then you will go the payment page, then only we will provide the QR code",
      "يحتاج تطلب أولا علشان تروح لصفحة الدفع وتصدر لك تذكرة فيها رمز دخول",
    ],
    try_again_pay: ["Try again payment", "الرجاء محاولة الدفع "],
    order_not_places: ["Order is not placed", "لم يتم الطلب"],
    order_placed_payment_failed: [
      "Order Placed but Payment Failed",
      "   تم تقديم الطلب لكن فشلت عملية الدفع",
    ],
    thanks_for_order_please_try_again: [
      "Thanks for Your Order, please try again the payment",
      " شكرا لطلبك .. الرجاء محاولة الدفع لاحقا",
    ],
    download: ["Download", " تحميل "],
    visa_master: ["VISA & MASTER CARD", "فيزا/ماستركارد"],
    mada_card: ["MADA CARD", "مدى "],
    pay_by_card: ["Pay by Card", "الدفع بالبطاقة"],
    entered_incorrect_otp: ["You entered incorrect OTP", "أدخلت رمز خاطىء"],
    please_try_again: ["Please try again", "الرجاء المحاولة مرة أخرى"],

    login_details_incorrect: [
      "The login details are  incorrect",
      "معلومات الدخول غير صحيحه",
    ],
    payment: ["Payment", "الدفع"],
    payment_type: ["Payment Type", " طريقة الدفع"],
    please_check_details: [
      "Please check details before checkout",
      "الرجاء التأكد من المعلومات قبل إتمام العملية",
    ],
    seconds_remian: ["Seconds remaining", "ثانية متبقيه"],
    verification: ["Verification", "التحقق"],
    redirection_failed: ["Redirection failed", "لم تنجح إعادة التوجيه"],
    country_code_required: ["Country Code is required", "  مفتاح الدولة مطلوب"],
    send_verification_code: ["Send verification code", "ارسل رمز التفعيل"],

    code: ["Code", "الكود"],
    verify: ["Verify", "تحقق"],
    display_scanner: [
      "Display under the scanner to unlock the door",
      "ضع شاشة جوالك تحت الماسح الضوئي لفتح باب الحمام",
    ],

    please_enter_verification_code: [
      "Please enter verification code sent to you",
      "الرجاء إدخال كود التحقق المرسل على جوالك المسجل لدينا",
    ],
    mobile_no: ["Mobile No", "رقم الجوال"],
    password: ["Password", "كلمة المرور"],
    language: ["Language", "اللغة"],
    hello: ["Hello", "مرحبًا"],
    sar: ["SAR", "ريال"],
    signout: ["Sign out", "تسجيل خروج"],
    english: ["English", "اللغة الإنجليزية"],
    arabic: ["Arabic", "عربي"],
    continue: ["Continue", "استمر"],
    please_enter_user_name: [
      "Please enter User Name",
      "الرجاء إدخال اسم المستخدم",
    ],
    please_enter_mobile_no: [
      "Please enter mobile number",
      "الرجاء إدخال رقم الجوال",
    ],
    user_name_required: ["User name is required", "اسم المستخدم مطلوب"],
    mobile_number_required: ["Mobile Number is required", "رقم الجوال مطلوب"],
    mobile_number_not_valid: [
      "Mobile Number is not Valid",
      "رقم الجوال غير صالح",
    ],
    password_required: ["Password is required", "كلمة المرور مطلوبة"],
    please_wait: ["Please wait", "الرجاء الإنتظار"],
    otp: ["Otp", "رمز الدخول"],
    otp_required: ["Otp is required", "رمز الدخول مطلوب"],
    otp_not_valid: ["Otp is not Valid", "رمز الدخول غير صالح"],
    number_of_access: ["Number of Access", "عدد مرات الدخول"],
    this_week: ["this week", "هذا  الأسبوع"],
    total_order: ["Total Order", "مجموع  الطلبات"],
    total_price: ["Total price", "المجموع"],
    number_of_orders: ["Number of Orders", "عدد  الطلبات"],
    total_orders_this_week: [
      "Total Orders this week",
      "عدد الطلبات هذا الأسبوع",
    ],
    live_orders: ["Live Orders", "الطلبات القائمة"],
    no_live_orders_yet: ["No Live Orders Yet", "لا يوجد طلبات قائمة حتى الآن"],
    paid_and_expired_orders: [
      "Paid and Expired Orders",
      "الطلبات المدفوعة و المنتهية",
    ],
    name: ["Name", "الأسم"],
    mobile: ["Mobile", "رقم الجوال"],
    organization: ["Organization", "المنشأة"],
    branch: ["Branch", "الفرع"],
    order_type: ["Order Type", "نوع الطلب"],
    price: ["Price", "السعر"],
    entry_time: ["Entry Time", "وقت الدخول"],
    exit_time: ["Exit Time", "وقت الخروج"],
    date_and_time: ["Date and Time", "التاريخ و الوقت"],
    checkout: ["Check out", "الذهاب للدفع"],

    status: ["Status", "الحالة"],
    dashboard: ["Dashboard", "لوحة البيانات"],
    organization_list: ["Organization List", "قائمة المنشآت"],
    organizations: ["Organizations", "المنشآت"],
    add: ["Add", "أضف"],
    branches: ["Branches", "الفروع"],
    group: ["Group", "مجموعة"],
    restrooms: ["Restrooms", "دورات المياه"],
    manager: ["Manager", "المدير"],
    manager_mobile: ["Manager Mobile", "جوال المدير"],
    created_at: ["Created At", "تم إنشاءه في"],
    active: ["Active", "نشط"],
    inactive: ["In active", "غير نشط"],
    organization_details: ["Organization Details", "معلومات المنشأة"],
    deactivate: ["De-activate", "تعطيل"],
    edit: ["Edit", "تحرير"],
    joined_at: ["Joined at", "انضم في"],
    zakat_orders: ["Zakat Orders", "طلبية متجر"],
    total_sales: ["Total Sales", "مجموع  المبيعات"],
    city: ["City", "المدينة"],
    country: ["Country", "الدولة"],
    working_hours: ["Working Hours", "ساعات العمل"],
    employees: ["Employees", "الموظفين"],
    edit_organization: ["Edit Organization", "تعديل ملف المنشأة"],
    organization_logo: ["Organization logo", "شعار المنشأة"],
    organization_arabic: ["Organization Arabic", "المنشأة عربي"],
    organization_english: ["Organization English", "المنشأة بالإنجليزي"],
    organization_manager: ["Organization Manager", "مدير المنشأة"],
    add_organization_manager: ["Add Organization Manager", "أضف مدير منشأة"],
    cr_number: ["CR number", "رقم السجل التجاري"],
    vat_number: ["Vat number", "الرقم الضريبي"],
    special_percentage: ["Special percentage", "النسبة الخاصة"],
    special_percentage_in_number: [
      "Special percentage in Number",
      "النسبة الخاصة بالأرقام",
    ],
    bank: ["Bank", "البنك"],
    bank_holder_name: ["Bank holder Name", "اسم البنك"],
    iban: ["IBAN", "الآيبان"],
    notes: ["Notes", "ملاحظات"],
    upload_vat_and_cr_files: [
      "Upload Vat and CR files",
      "أرفع السجل التجاري و الرقم الضريبي",
    ],
    make_sure_upload_file: [
      "Make sure you upload the file",
      "تأكد آنك رفعت الملف",
    ],
    vat_fifteen_percent: ["VAT 15%", "الضريبة 15%"],

    vat_file: ["Vat file", "ملف الضريبة"],
    cr_file: ["CR file", "ملف السجل التجاري"],
    drag_drop_vat_file_here: [
      "Drag and drop Vat file here",
      "قم سحب و إضافة ملف الضريبة هنا",
    ],
    drag_drop_cr_file_here: [
      "Drag and drop CR file here",
      "قم سحب و إضافة السجل التجاري هنا",
    ],
    save_changes: ["Save changes", "حفظ المتغيرات"],
    loading: ["Loading", "قيد التحميل"],
    add_new: ["Add new", "أضف جديد"],
    please_select_to_add: [
      "Please select what you want to add",
      "الرجاء تحديد ما تريد إضافته",
    ],
    create_organization: ["Create an organization", "أنشئ متجر (منشأة)"],
    create_branch: ["Create a branch", "أنشئ فرع"],
    create_group: ["Create a group", "أنشئ مجموعة"],
    create_restroom: ["Create a restroom", "أضف دورة مياه"],
    create_employee: ["Create an employee", "أضف موظف"],
    add_organization: ["Add organization", "أضف منشأة"],
    edit_branch: ["Edit Branch", "تحرير فرع"],
    add_branch_manager: ["Add branch manager", "أضف مدير فرع"],
    branch_name_arabic: ["Branch name Arabic", "اسم الفرع – عربي"],
    branch_name_english: ["Branch name English", "اسم الفرع – انقلش"],
    store_location: ["Store location", "موقع المتجر"],
    select_on_the_map: ["Select on the Map", "حدد على الخريطة"],
    sunday: ["Sunday", "الأحد"],
    monday: ["Monday", "الأثنين"],
    tuesday: ["Tuesday", "الثلاثاء"],
    wednesday: ["Wednesday", "الأربعاء"],
    thursday: ["Thursday", "الخميس"],
    friday: ["Friday", "الجمعة"],
    saturday: ["Saturday", "السبت"],
    add_branch: ["Add branch", "أضف فرع"],
    reference_id: ["Reference ID", "الرقم المرجعي"],
    amount: ["Amount", "المبلغ"],
    actions: ["Actions", "الأحداث"],
    group_details: ["Group details", "تفاصيل المجموعة"],
    group_detail: ["Group detail", "تفاصيل المجموعة"],
    qr_logo: ["QR Logo", "QR شعار"],
    device_id: ["Device ID", "رقم الجهاز"],
    get_master_qr_1: ["Get Master QR 1", "أحصل على ماستر QR1"],
    get_master_qr_2: ["Get Master QR 2", "أحصل على ماستر QR2"],
    edit_group: ["Edit Group", "تحرير المجموعة"],
    add_group: ["Add Group", "أضف مجموعة"],
    group_info: ["Group Info", "معلومات المجموعة"],
    branch_id: ["Branch ID", "رقم الفرع"],
    floor: ["Floor", "الدور"],
    gender: ["Gender", "الجنس"],
    seat_type: ["Seat Type", "نوع المقعد"],
    add_restroom: ["Add restroom", "أضف دورة مياه"],
    edit_restroom: ["Edit Restroom", "تحرير دورة مياه"],
    restroom_info: ["Restroom Info", "معلومات دورة مياه"],
    cleaner: ["Cleaner", "المنظف"],
    select: ["select", "إختر"],
    add_cleaner: ["Add cleaner", "أضف منظف"],
    add_devices: ["Add devices", "أضف جهاز"],
    restroom_location: ["Restroom Location", "موقع دورة المياه"],
    restroom_floor: ["Restroom Floor", "دور دورة المياه"],
    restroom_gender: ["Restroom Gender", "جنس دورة المياه"],
    male: ["Male", "ذكر"],
    female: ["Female", "أنثى"],
    unisex: ["Unisex", "للجنسين"],
    paid: ["Paid", "مدفوع"],
    yes: ["Yes", "نعم"],
    no: ["No", "لا"],
    western: ["Western", "غربي"],
    add_employee: ["Add Employee", "أضف موظف"],
    master_access: ["Master Access", "مفتاح ماستر"],
    email: ["Email", "البريد الإلكتروني"],
    employee_type: ["Employee Type", "نوع  الموظف"],
    add_new_employee: ["Add new Employee", "أضف موظف جديد"],
    please_enter_employee_info: [
      "Please enter employee information",
      "الرجاء أدخل معلومات الموظف",
    ],
    edit_employee: ["Edit Employee", "تحرير معلومات الموظف"],
    users: ["Users", "المستخدمين"],
    user: ["User", "الموظف"],
    admin: ["Admin", "أدمين"],
    agent: ["Agent", "ممثل"],
    admins: ["Admins", "الأدمين"],
    wallet: ["Wallet", "المحفظة"],
    wallet_balance: ["Wallet balance", "رصيد المحفظة"],
    details: ["Details", "التفاصيل"],

    joining_date: ["Joining Date", "تاريخ الالتحاق"],
    role: ["Role", "دور (الوظيفي)"],
    add_admin_agent: ["Add Admin/Agent", "أضف أدمين/ممثل"],
    edit_admin_agent: ["Edit Admin/Agent", "عدل أدمين/ممثل"],
    please_enter_required_info: [
      "Please enter required information",
      "الرجاء إدخال المعلومات المطلوبة",
    ],
    order_details: ["Order details", "تفاصيل الطلب"],
    update: ["Update", "تحديث"],
    completed: ["Completed", "مكتملة"],
    occupied: ["Occupied", "مشغول"],
    waiting_for_payment: ["Waiting for payment", "بانتظار الدفع"],
    payment_success: ["Payment success", "تم الدفع بنجاح"],
    order_expired: ["Order expired", "الطلب منتهي"],
    paid_and_expired: ["Paid and expired", "مدفوع و منتهي"],
    order_cancelled: ["Order cancelled", "الطلب ملغي"],
    payment_retry: ["Payment retry", "إعادة عملية الدفع"],
    payment_failed: ["Payment failed", "فشل عملية الدفع"],
    tkrm_order: ["TKRM order", "طلبية تكرم"],
    zakat_order: ["ZAKAT order", "طلبية المتجر"],
    order_no: ["Order No", "رقم الطلب"],
    unit: ["Unit", "الوحدة"],
    restroom_access: ["Restroom Access", "عملية دخول دورة المياه"],
    restroom_id: ["Restroom ID", "رقم دورة المياه"],
    recent_activities: ["Recent Activities", "الأنشطة الحالية"],
    devices: ["Devices", "الأجهزة"],
    // device_id: ['Device ID', 'رقم الجهاز'],
    device_secret: ["Device Secret", "الرقم السري للجهاز"],
    device_type: ["Device Type", "نوع الجهاز"],
    add_device: ["Add Device", "أضف جهاز"],
    edit_device: ["Edit Device", "تعديل جهاز"],
    add_amenity: ["Add amenity", "أضف ملحق"],
    amenities: ["Amenities", "مرفقات دورة المياه"],
    image: ["Image", "صورة"],
    name_arabic: ["Name Arabic", "الاسم عربي"],
    name_english: ["Name English", "الاسم انجليزي"],
    edit_amenity: ["Edit Amenity", "تعديل المرافق"],
    transactions: ["Transactions", "العمليات"],
    transaction: ["Transaction", "العملية"],
    transaction_details: ["Transaction Details", "تفاصيل العملية"],
    total_commission: ["Total Commission", "مجموع العمولة"],
    total: ["Total", "المجموع الكلي"],

    order_transaction: ["Order Transaction", "عملية الطلب"],
    commission_transaction: ["Commission Transaction", "العمولة"],
    zakat_transaction: ["ZAKAT Transaction", "عملية المتجر"],
    transaction_id: ["Transaction ID", "رقم العملية"],
    description: ["Description", "الوصف"],
    from_name: ["From Name", "من الأسم"],
    to_name: ["To Name", "إلى الأسم"],
    type: ["Type", "النوع"],
    order_id: ["Order ID", "رقم الطلبية"],
    orders: ["Orders", "الطلبات"],
    updated_at: ["Updated At", "تحدثت في"],
    rating: ["Rating", "التقييم"],
    ratings: ["Ratings", "التقييمات"],
    notifications: ["Notifications", "الإشعارات"],
    settings: ["Settings", "الإعدادات"],
    saving_user_credentials: ["Saving User credentials", "حفظ معلومات العميل"],
    collecting_user_details: [
      "Collecting User details while login",
      "حفظ معلومات العميل عن الدخول",
    ],
    on: ["On", "مفتوح"],
    off: ["Off", "مغلق"],
    value_added_tax: ["Value added tax", "ضريبة القيمة المضافة"],
    of_amount: ["Of amount", "من القيمة"],
    commission_percentage: ["Commission percentage", "نسبة العمولة"],
    commission_details: ["Commission details", "تفاصيل العمولة"],
    percentage: ["Percentage", "النسبة"],
    private_chat: ["Private Chat", "محادثة خاصة"],
    support: ["Support", "الدعم"],
    active_chats: ["Active Chats", "المحادثات النشطة"],
    type_a_message: ["Type a message", "اكتب رسالة"],
    admin_chat: ["Admin Chat", "محادثة الأدمن"],

    jan: ["Jan", "يناير"],
    feb: ["Feb", "فبراير"],
    mar: ["Mar", "مارس"],
    apr: ["Apr", "أبريل"],
    may: ["May", "مايو"],
    jun: ["Jun", "يونيو"],
    jul: ["Jul", "يوليو"],
    aug: ["Aug", "أغسطس"],
    sep: ["Sep", "سبتمبر"],
    oct: ["Oct", "أكتوبر"],
    nov: ["Nov", "نوفمبر"],
    dec: ["Dec", "ديسمبر"],
  },
};

export function getTranslation(_key, rtl) {
  const lang = !rtl ? 0 : 1;
  let key;
  let text;
  try {
    key = _key.toLowerCase().replace(/ /g, "_").replace(/\./g, "");
    text = data.resources[key][lang];
  } catch (e) {
    // console.log('error', e);
    // text = '';
    text = _key;
  }
  return text;
}

export function getBothTranslations(key) {
  // console.log('getBothTranslations')
  return data.resources[key];
}
