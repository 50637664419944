import React, { useState, useRef, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import Navigation from "./pages/Naviagtion/Navigation";
import Footer from "./pages/Footer/Footer";
import Home from "./pages/Home/Home";
import Terms from "./pages/Terms";
import About from "./pages/About/About";
// import Help from "./pages/Help/Help";
import Store from "./pages/Store/Store";
import Preloader from "./components/preloader/Preloader";
import gsap from "gsap"; // Import GSAP for animations
// import ApplePay from "./pages/ApplePay";

const App: React.FC = () => {
  const [loading, setLoading] = useState(true); // State for managing preloader visibility
  const appContentRef = useRef<HTMLDivElement | null>(null); // Ref for main content

  // Function to handle the end of preloader animation
  const handlePreloaderComplete = () => {
    setLoading(false); // Hide preloader

    if (appContentRef.current) {
      // Fade in the main content when preloader completes with a smooth transition
      gsap.fromTo(
        appContentRef.current,
        { opacity: 0 }, // Start with opacity 0
        { opacity: 1, duration: 1.5, ease: "power3.out" } // Smooth fade-in effect
      );
    }
  };

  useEffect(() => {
    if (!loading && appContentRef.current) {
      gsap.fromTo(
        appContentRef.current,
        { opacity: 0 }, // Start with opacity 0
        { opacity: 1, duration: 1.5, ease: "power3.out" } // Smooth fade-in effect
      );
    }
  }, [loading]);

  return (
    <ChakraProvider>
      {loading ? (
        <Preloader onComplete={handlePreloaderComplete} />
      ) : (
        <div ref={appContentRef} style={{ opacity: 0 }}>
          <Router>
            <Navigation />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/about" element={<About />} />
              <Route path="/store" element={<Store />} />
              {/* <Route path="/help" element={<Help />} /> */}
              <Route path="*" element={<Home />} />
              {/* <Route
                path="/.well-known/apple-developer-merchantid-domain-association.txt"
                element={<ApplePay />}
              /> */}
            </Routes>
            <Footer />
          </Router>
        </div>
      )}
    </ChakraProvider>
  );
};

export default App;
