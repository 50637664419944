import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap";

const Preloader: React.FC<{ onComplete: () => void }> = ({ onComplete }) => {
  const preloaderRef = useRef<HTMLDivElement | null>(null);
  const textRef = useRef<HTMLDivElement | null>(null);
  const [percentage, setPercentage] = useState(0); // To track loading percentage

  useEffect(() => {
    // Increment the loading percentage
    const interval = setInterval(() => {
      setPercentage((prev) => {
        if (prev < 100) {
          return prev + 1;
        } else {
          clearInterval(interval);
          return 100;
        }
      });
    }, 20); // Adjust the speed by modifying the interval

    // GSAP text animation for pulsing effect
    gsap.fromTo(
      textRef.current,
      { opacity: 0.5, scale: 0.8 },
      { opacity: 1, scale: 1, duration: 0.5, repeat: -1, yoyo: true }
    );

    // Once loading reaches 100%, hide the preloader
    if (percentage === 100) {
      gsap.to(preloaderRef.current, {
        opacity: 0,
        duration: 1, // Smooth fade-out duration
        delay: 1, // Simulate loading time
        ease: "power2.out",
        onComplete: onComplete,
      });
    }

    return () => clearInterval(interval); // Clear interval on cleanup
  }, [percentage, onComplete]);

  return (
    <div
      ref={preloaderRef}
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#000",
        color: "#fff",
        fontSize: "24px",
      }}
    >
      <div ref={textRef}>Loading... {percentage}%</div>
    </div>
  );
};

export default Preloader;
