import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Flex,
  Link,
  Image,
  Container,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  useMediaQuery,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { FaGlobe } from "react-icons/fa";
import { HoverStyle, linkStyles } from "./style";
import { useLayoutDirection } from "../../layout/LayoutDireactionContext";
import { getTranslation } from "../../utility/Translation";

const Navigation: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isLargerThanMobile] = useMediaQuery("(min-width: 768px)");
  const [navbarBg, setNavbarBg] = useState("transparent");
  const { isRTL, toggleLayoutDirection } = useLayoutDirection();

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  // Add scroll listener to handle background color change
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setNavbarBg("rgba(0, 0, 0, 0.8)"); // Set background after scrolling
      } else {
        setNavbarBg("transparent"); // Transparent background when at the top
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      right="0"
      zIndex="1000"
      bg={navbarBg}
      transition="background-color 0.3s ease"
      w="95%" // Set width to 95%
      borderRadius="lg" // Add rounded corners
      mx="auto" // Center the navbar horizontally
      mt={2} // Add some margin from the top of the page
    >
      <Container maxW="container.xl">
        <Flex
          as="nav"
          align="center"
          justifyContent="space-between"
          p={4}
          color="white"
        >
          <Flex align="center">
            <Link as={RouterLink} to="/" fontSize="xl" fontWeight="bold">
              <Box bg="white" p={2} ml="5%" mr="5%">
                <Image src="../tkrm.svg" alt="My Logo" width="80px" />
              </Box>
            </Link>
            {isLargerThanMobile && (
              <>
                <Link
                  as={RouterLink}
                  to="/about"
                  ml="1em"
                  mr="1em"
                  fontWeight="semibold"
                  style={linkStyles}
                  _hover={HoverStyle}
                >
                  {getTranslation("about", isRTL)}
                </Link>

                <Link
                  as={RouterLink}
                  style={linkStyles}
                  fontWeight="semibold"
                  to="/terms"
                  ml="1em"
                  mr="1em"
                  _hover={HoverStyle}
                >
                  {getTranslation("terms", isRTL)}
                </Link>
              </>
            )}
          </Flex>

          <Flex align="center">
            <Link
              ml="10%"
              mr="10%"
              fontWeight="semibold"
              style={{
                padding: "0.5rem 1rem",
                borderRadius: "1.5rem",
                display: "flex",
                alignItems: "center",
              }}
              _hover={HoverStyle}
              onClick={() => {
                toggleLayoutDirection(!isRTL);
                // window.location.reload();
              }}
            >
              <FaGlobe
                style={{ marginRight: "0.5rem", marginLeft: "0.5rem" }}
              />
              {getTranslation("lang_rtl", !isRTL)}
            </Link>

            <IconButton
              icon={<HamburgerIcon />}
              aria-label="Open Menu"
              display={{ base: "block", md: "none" }}
              onClick={toggleDrawer}
            />
          </Flex>
        </Flex>
      </Container>

      <Drawer
        isOpen={isDrawerOpen}
        placement="top"
        size={"full"}
        onClose={toggleDrawer}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            bg="black"
          >
            <>
              <span style={{ color: "white" }}>Menu</span>
              <IconButton
                icon={<CloseIcon />}
                aria-label="Close Menu"
                onClick={toggleDrawer}
              />
            </>
          </DrawerHeader>
          <DrawerBody bg="white">
            {!isLargerThanMobile && (
              <>
                <Box m="16px 0">
                  <Link
                    as={RouterLink}
                    to="/about"
                    onClick={toggleDrawer}
                    fontSize="xl"
                    fontWeight="bold"
                  >
                    {getTranslation("about", isRTL)}
                  </Link>
                </Box>

                <Box m="16px 0">
                  <Link
                    as={RouterLink}
                    to="/terms"
                    onClick={toggleDrawer}
                    fontSize="xl"
                    fontWeight="bold"
                  >
                    {getTranslation("terms", isRTL)}
                  </Link>
                </Box>
              </>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default Navigation;
