import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Icon,
  useMediaQuery,
  SimpleGrid,
  Center,
  Text,
  Heading,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { getTranslation } from "../../utility/Translation";
import { useLayoutDirection } from "../../layout/LayoutDireactionContext";
import { content, content1, content2 } from "./Content";
import {
  titleStyle,
  zoomOutVariants,
  slideDownVariants,
  slideUpVariants,
  underlineStyleForside,
  underlineStyleForsideRes,
  staticImageSectionStyle1,
  staticImageSectionStyle2,
  underlineStyleForsideResRight,
  underlineStyleForsideRight,
} from "./style";
import HomePage from "../../components/homepage/HomePage";
import Content from "../../components/section2/Content";
import Hero from "../../components/hero/Hero";

const Home: React.FC = () => {
  const [isLargerThanMobile] = useMediaQuery("(min-width: 768px)");
  const { isRTL } = useLayoutDirection();
  // const [hoveredCardId, setHoveredCardId] = useState<number | null>(null);
  const [firstPage, setFirstPage] = useState(true);
  const [loading, setLoading] = useState(false);
  const handleChange = (value: boolean) => {
    setLoading(true);
    setFirstPage(value);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  };

  const contentRef = useRef<HTMLDivElement | null>(null);

  const updateContentVisibility = () => {
    if (contentRef.current) {
      const contentTop = contentRef.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      const contentVisible = contentTop < windowHeight;
      return contentVisible;
    }
    return false;
  };

  const [contentVisible, setContentVisible] = useState(
    updateContentVisibility()
  );

  useEffect(() => {
    const handleScroll = () => {
      const newContentVisibility = updateContentVisibility();
      if (newContentVisibility !== contentVisible) {
        setContentVisible(newContentVisibility);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [contentVisible, firstPage]);

  return (
    <>
      {/* first page */}

      <div>
        <Hero />
        {/* <HomePage
          firstPage={firstPage}
          handleChange={handleChange}
          loading={loading}
        /> */}
        {/* second content */}
        {/* <Content
          firstPage={firstPage}
          contentRef={contentRef}
          contentVisible={contentVisible}
        /> */}
      </div>
      {/* <div>
        <Box
          style={staticImageSectionStyle1}
          p={isLargerThanMobile ? 10 : 1}
          ref={contentRef}
        >
          <motion.div
            initial="hidden"
            animate={contentVisible ? "visible" : "hidden"}
            variants={slideDownVariants}
          >
            {firstPage ? (
              <Box>
                <Box fontWeight="400" p={isLargerThanMobile ? 10 : 5}>
                  <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} spacing={12}>
                    {content.map((cardNumber) => (
                      <Box key={cardNumber.id}>
                        <Center>
                          <motion.div
                            variants={zoomOutVariants}
                            initial="initial"
                            whileHover="hover" // Apply the zoom-out animation on hover
                          >
                            <Box
                              boxShadow="lg"
                              p={4}
                              borderRadius="md"
                              textAlign="center"
                              cursor={"pointer"}
                              height={isLargerThanMobile ? "340px" : "400px"}
                              width={isLargerThanMobile ? "500px" : "100%"}
                              style={{ borderTop: "5px solid #0668DF" }}
                            >
                              <Box>
                                <Icon
                                  as={cardNumber.icon}
                                  w={16}
                                  h={6}
                                  mb={2}
                                  color={"blue"}
                                />
                              </Box>
                              <Heading
                                as="h2"
                                size="md"
                                maxWidth={"250px"}
                                m={"auto"}
                                color={"#0668DF"}
                              >
                                {getTranslation(cardNumber.title, isRTL)}
                              </Heading>
                              <Box p={7}>
                                <Text fontWeight={"semibold"}>
                                  {getTranslation(cardNumber.body, isRTL)}
                                </Text>
                              </Box>
                            </Box>
                          </motion.div>
                        </Center>
                      </Box>
                    ))}
                  </SimpleGrid>
                </Box>
              </Box>
            ) : (
              <Box textAlign="center">
                <Box fontWeight="400" p={isLargerThanMobile ? 10 : 5}>
                  <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} spacing={12}>
                    {content1.map((cardNumber) => (
                      <Box key={cardNumber.id}>
                        <Center>
                          <motion.div
                            variants={zoomOutVariants}
                            initial="initial"
                            whileHover="hover" // Apply the zoom-out animation on hover
                          >
                            <Box
                              boxShadow="lg"
                              p={4}
                              borderRadius="md"
                              textAlign="center"
                              cursor={"pointer"}
                              height={isLargerThanMobile ? "340px" : "400px"}
                              width={isLargerThanMobile ? "500px" : "100%"}
                              style={{ borderTop: "5px solid #0668DF" }}
                            >
                              <Box>
                                <Icon
                                  as={cardNumber.icon}
                                  w={16}
                                  h={6}
                                  mb={2}
                                  color={"blue"}
                                />
                              </Box>
                              <Heading
                                as="h2"
                                size="md"
                                maxWidth={"250px"}
                                m={"auto"}
                                color={"#0668DF"}
                              >
                                {getTranslation(cardNumber.title, isRTL)}
                              </Heading>
                              <Box p={7}>
                                <Text fontWeight={"semibold"}>
                                  {getTranslation(cardNumber.body, isRTL)}
                                </Text>
                              </Box>
                            </Box>
                          </motion.div>
                        </Center>
                      </Box>
                    ))}
                  </SimpleGrid>
                </Box>
              </Box>
            )}
          </motion.div>
        </Box>
      </div> */}

      {/* {!firstPage && (
        <Box
          style={staticImageSectionStyle2}
          p={isLargerThanMobile ? 10 : 0}
          // ref={contentRef}
        >
          <motion.div
            initial="hidden"
            animate={contentVisible ? "visible" : "hidden"}
            variants={slideUpVariants}
          >
            <Box>
              <Box>
                <Text
                  p={3}
                  fontWeight="bold"
                  fontSize="xl"
                  style={{ textShadow: "0 0 10px rgba(0,0,0,0.1)" }}
                >
                  More Owner's features
                </Text>
                <Heading as="h1" size="xl" style={titleStyle}>
                  {isLargerThanMobile ? (
                    <Box
                      style={
                        isRTL
                          ? underlineStyleForsideResRight
                          : underlineStyleForsideRes
                      }
                    />
                  ) : (
                    <Box
                      style={
                        isRTL
                          ? underlineStyleForsideRight
                          : underlineStyleForside
                      }
                    />
                  )}
                </Heading>
              </Box>

              <Box fontWeight="400" p={isLargerThanMobile ? 10 : 5}>
                <SimpleGrid columns={{ base: 1 }} spacing={4}>
                  {content2.map((cardNumber) => (
                    <Box key={cardNumber.id}>
                      <motion.div
                        variants={zoomOutVariants}
                        initial="initial"
                        whileHover="hover" // Apply the zoom-out animation on hover
                      >
                        <Box
                          boxShadow="lg"
                          p={10}
                          borderRadius="md"
                          cursor={"pointer"}
                        >
                          <Heading as="h2" size="md" m={"auto"} pb={2}>
                            {getTranslation(cardNumber.title, isRTL)}
                          </Heading>
                          <Box>
                            <Text fontWeight={"semibold"}>
                              {getTranslation(cardNumber.body, isRTL)}
                            </Text>
                          </Box>
                        </Box>
                      </motion.div>
                    </Box>
                  ))}
                </SimpleGrid>
              </Box>
            </Box>
          </motion.div>
        </Box>
      )} */}
    </>
  );
};

export default Home;
